@font-face {
    font-family: kfont;
    src: url(../fonts/kfont.eot);
    src: url(../fonts/kfont.eot?#iefix) format('eot'),url(../fonts/kfont.woff2) format('woff2'),url(../fonts/kfont.woff) format('woff'),url(../fonts/kfont.ttf) format('truetype'),url(../fonts/kfont.svg#kfont) format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: block
}

.icon-arrowleft:before,
.icon-arrowright:before,
.icon-arrowup:before,
.icon-business:before,
.icon-clock:before,
.icon-home:before,
.icon-mail:before,
.icon-map:before,
.icon-mapmarker:before,
.icon-phone2:before,
.icon-phone:before,
.icon-price:before,
.icon-quality:before,
.icon-sealing:before {
    font-family: kfont;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    text-decoration: inherit
}

.icon-arrowleft:before {
    content: "\EA01"
}

.icon-arrowright:before {
    content: "\EA02"
}

.icon-arrowup:before {
    content: "\EA03"
}

.icon-business:before {
    content: "\EA04"
}

.icon-clock:before {
    content: "\EA05"
}

.icon-mail:before {
    content: "\EA06"
}

.icon-map:before {
    content: "\EA07"
}

.icon-mapmarker:before {
    content: "\EA08"
}

.icon-phone:before {
    content: "\EA09"
}

.icon-phone2:before {
    content: "\EA0A"
}

.icon-price:before {
    content: "\EA0B"
}

.icon-quality:before {
    content: "\EA0C"
}

.icon-sealing:before {
    content: "\EA0D"
}

.icon-home:before {
    content: "\EA0E"
}