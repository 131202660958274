@font-face {
	font-family: "kfont";
	src: url('../fonts/kfont.eot');
	src: url('../fonts/kfont.eot?#iefix') format('eot'),
	url('../fonts/kfont.woff2') format('woff2'),
	url('../fonts/kfont.woff') format('woff'),
	url('../fonts/kfont.ttf') format('truetype'),
	url('../fonts/kfont.svg#kfont') format('svg');
	font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icon-exploitants:before,
.icon-facebook:before,
.icon-fusee:before,
.icon-linkedin:before,
.icon-localisation:before,
.icon-mail:before,
.icon-missions:before,
.icon-phone:before,
.icon-time:before,
.icon-twitter:before,
.icon-salaries:before,
.icon-arrowleft:before,
.icon-arrowright:before,
.icon-arrowup:before,
.icon-home:before {
  font-family: "kfont";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  text-decoration: inherit;
}

.icon-exploitants:before { content: "\EA01" }
.icon-facebook:before { content: "\EA02" }
.icon-fusee:before { content: "\EA03" }
.icon-linkedin:before { content: "\EA04" }
.icon-localisation:before { content: "\EA05" }
.icon-mail:before { content: "\EA06" }
.icon-missions:before { content: "\EA07" }
.icon-phone:before { content: "\EA08" }
.icon-time:before { content: "\EA0A" }
.icon-twitter:before { content: "\EA0B" }
.icon-salaries:before { content: "\EA0C" }
.icon-arrowleft:before { content: "\EA0D" }
.icon-arrowright:before { content: "\EA0E" }
.icon-arrowup:before { content: "\EA0F" }
.icon-home:before { content: "\EA10" }
