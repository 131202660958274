.no-csstransforms3d .mp-pusher,
.no-js .mp-pusher {
    padding-left: 0
}

.no-csstransforms3d .mp-menu .mp-level,
.no-js .mp-menu .mp-level {
    display: none
}

.no-csstransforms3d .mp-menu > .mp-level,
.no-js .mp-menu > .mp-level {
    display: block
}

.mp-menu {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100vh;
    background: #fff;
    transform: translateX(-100%);
    transition: transform .5s
}

.mp-level > ul > li > a > span {
    color: #6b7e9b
}

.mp-level > ul > li > a:focus > span,
.mp-level > ul > li > a:hover > span {
    color: #6b7e9b
}

.mp-level {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100vh - 50px);
    overflow: hidden;
    background: #f4f4f4;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%)
}

.mp-menu > .mp-level {
    top: 6.5rem
}

.mp-menu > .mp-level.mp-level-open {
    height: calc(100% - 50px)
}

.mp-level.mp-level-open {
    height: calc(100vh - 50px);
    overflow: auto
}

.mp-level.mp-level-open.mp-level-overlay {
    overflow: hidden;
    height: 200vh
}

.mp-level.mp-level-open.mp-level-overlay > ul > li:not(.m_current) {
    opacity: 0;
    visibility: hidden
}

.mp-level.mp-level-overlay {
    cursor: pointer
}

.mp-level.mp-level-overlay.mp-level:before {
    width: 100%;
    height: 100%;
    background: 0 0;
    opacity: 1
}

.mp-level,
.mp-pusher {
    -webkit-transition: transform .5s;
    -moz-transition: transform .5s;
    transition: transform .5s
}

.mp-overlap .mp-level.mp-level-open {
    box-shadow: 1px 0 2px rgba(0,0,0,.2);
    -webkit-transform: translateX(-40px);
    -ms-transform: translateX(-40px);
    transform: translateX(-40px)
}

.mp-menu > .mp-level,
.mp-menu > .mp-level.mp-level-open,
.mp-menu.mp-overlap > .mp-level,
.mp-menu.mp-overlap > .mp-level.mp-level-open {
    box-shadow: none;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    -webkit-transition: transform .5s,-webkit-transform .5s;
    -moz-transition: transform .5s,-moz-transform .5s;
    transition: transform .5s
}

.mp-cover .mp-level.mp-level-open {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0)
}

.mp-cover .mp-level.mp-level-open > ul > li > .mp-level:not(.mp-level-open) {
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%)
}

.mp-menu .title {
    margin: 0 20px;
    color: rgba(0,0,0,.7);
    text-shadow: 0 0 1px rgba(0,0,0,.1);
    font-size: 1.6rem;
    min-height: 40px;
    line-height: 4rem;
    padding: .7rem 0;
    text-transform: uppercase;
    font-weight: 400;
    display: block
}

.mp-menu .title .text {
    vertical-align: middle;
    margin-left: 20px
}

.mp-menu .title .picto + .text {
    margin-left: 0
}

.mp-menu .close_bloc .title,
.mp-menu .mp-level .mp-level .mp-level .title {
    color: #fff
}

.mp-menu .mp-level .mp-level .mp-level .title a {
    vertical-align: middle;
    line-height: 1.8rem;
    display: inline-block;
    padding-bottom: 3px
}

.mp-menu .title a:hover,
.mp-menu ul li .title a:hover {
    background: 0 0;
    color: #6b7e9b
}

.mp-menu .title .picto,
.mp-menu .title a .picto {
    font-size: 4rem;
    line-height: 4rem;
    overflow: visible;
    width: auto;
    margin: 0 5px
}

.mp-menu .title .picto + .centered-height {
    width: calc(100% - 60px)
}

.mp-menu.mp-overlap .title:before {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 8px;
    font-size: 75%;
    line-height: 1.8;
    opacity: 0;
    -webkit-transition: opacity .3s,-webkit-transform .1s .3s;
    -moz-transition: opacity .3s,-moz-transform .1s .3s;
    transition: opacity .3s,transform .1s .3s;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%)
}

.mp-overlap .mp-level.mp-level-overlay > .title:before {
    opacity: 1;
    -webkit-transition: -webkit-transform .3s,opacity .3s;
    -moz-transition: -moz-transform .3s,opacity .3s;
    transition: transform .3s,opacity .3s;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0)
}

.mp-menu ul {
    list-style: none;
    text-align: left;
    overflow: hidden
}

.mp-menu > .mp-level > ul {
    margin: 0;
    padding: 0;
    overflow: auto;
    width: 100%;
    height: 100%;
    position: relative
}

.mp-menu ul li:before {
    position: absolute;
    left: 10px;
    z-index: -1;
    color: rgba(0,0,0,.8);
    line-height: 3rem
}

.mp-menu ul li > a {
    display: block;
    padding: .7em 1em .7em 1em;
    outline: 0;
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 400;
    text-transform: uppercase;
    -webkit-transition: background .3s,box-shadow .3s;
    -moz-transition: background .3s,box-shadow .3s;
    transition: background .3s,box-shadow .3s
}

.mp-menu ul li > a .icon {
    font-size: 1.2rem
}

.mp-level a {
    color: #6b7e9b
}

#mp-menu .mp-level ul {
    list-style: none
}

#mp-menu .mp-level-2 a {
    text-transform: none;
    line-height: 2rem;
    vertical-align: middle
}

#mp-menu .mp-level-2 .centered-height a {
    line-height: 1;
    display: inline-block;
    float: none;
    vertical-align: middle
}

#mp-menu .mp-level-2 .c_container a,
#mp-menu .mp-level-2 .c_container span {
    color: #000;
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 400;
    text-transform: uppercase
}

#mp-menu .mp-level-2 .c_container span {
    color: #206a2f
}

#mp-menu .mp-level-2 a.mp-back {
    text-transform: uppercase
}

#mp-menu .mp-level-2 a.mp-back:hover {
    color: #6b7e9b
}

#mp-menu .mp-level-2 ul {
    padding: 0;
    margin: 0
}

#mp-menu .mp-level-2 li {
    padding: 0 5px
}

.mp-level .espace_mobile .text,
.mp-level a .text {
    padding-left: 0;
    vertical-align: middle;
    -webkit-transition: border-color .3s ease-in,padding .3s ease-in;
    -moz-transition: border-color .2s ease-in,padding .2s ease-in;
    transition: border-color .2s ease-in,padding .2s ease-in;
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 400;
    text-transform: uppercase
}

.mp-level .espace_mobile:hover .text,
.mp-level a:hover .text {
    padding-left: 5px
}

.mp-level .espace_mobile {
    background-color: transparent;
    border: none;
    line-height: 2.2rem;
    padding: .7em 1em .7em 1em;
    outline: 0;
    cursor: pointer;
    color: #fff
}

.mp-level .espace_mobile:hover .picto,
.mp-level .espace_mobile:hover .text {
    color: #000
}

.mp-level .espace_mobile .picto {
    margin: 0 10px 0 0;
    font-size: 2.2rem;
    width: 24px;
    overflow: visible
}

.mp-level > ul > li > a {
    box-shadow: inset 0 1px rgba(0,0,0,.2)
}

.mp-menu.out ul li a:hover {
    background: rgba(0,0,0,.2);
    box-shadow: inset 0 -1px transparent
}

.mp-level.mp-level-overlay > ul > li:first-child > a,
.mp-menu.out .mp-level.mp-level-overlay > ul > li > a {
    box-shadow: inset 0 -1px transparent
}

.mp-level.mp-level-overlay > ul > li:first-child > a {
    box-shadow: inset 0 -1px transparent,inset 0 1px transparent
}

#mp-menu .mp-level .picto_menu {
    position: static;
    margin: 0 0 0 6px;
    vertical-align: middle;
    border-color: transparent transparent transparent rgba(0,0,0,.7);
    -webkit-transition: border-color .2s ease-in,margin .3s ease-in;
    -moz-transition: border-color .2s ease-in,margin .3s ease-in;
    transition: border-color .2s ease-in,margin .3s ease-in
}

#mp-menu .mp-level a:hover .picto_menu {
    border-color: transparent transparent transparent #fff
}

.mp-back {
    background: rgba(0,0,0,.1);
    outline: 0;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
    display: block;
    font-size: 1.2em;
    padding: 1em 1em 1em 2.4em;
    position: relative;
    box-shadow: inset 0 1px rgba(0,0,0,.1);
    -webkit-transition: color .2s,background .3s,padding .2s;
    -moz-transition: color .2s,background .3s,padding .2s;
    transition: color .2s,background .3s,padding .2s
}

.mp-back:hover {
    padding: 1em 1em 1em 2.7em
}

.mp-menu.out .mp-level.mp-level-overlay > .mp-back {
    background: 0 0;
    box-shadow: none;
    color: transparent
}

#mp-menu .mp-back::before,
#mp-menu .mp-level-2 .mp-back::before {
    content: "\EA0D";
    font-family: kfont!important;
    vertical-align: middle;
    margin-right: 20px
}

#mp-menu .mp-back .picto_menu {
    border-color: transparent rgba(0,0,0,.7) transparent transparent
}

#mp-menu .mp-level .mp-back:hover .picto_menu {
    border-color: transparent #fff transparent transparent
}

.mp-level li {
    margin-left: 0
}

.m_event:after {
    content: "\EA0E";
    font-family: kfont!important;
    margin-right: 40px;
    vertical-align: middle;
    float: right
}

#fancybox-content > div,
.fancybox-inner {
    overflow: hidden!important
}

#fancybox-content > div img {
    width: 100%
}

.mp-pusher {
    transition: transform .5s;
    -webkit-transition: transform .5s
}

.fancy_visible .scroller,
.menu_visible .scroller {
    overflow: hidden;
    height: 100%
}

.menu_visible html {
    overflow: hidden
}

.mp-fancy {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transform: translateX(-340px);
    -webkit-transform: translateX(-340px);
    -ms-transform: translateX(-340px);
    width: 340px;
    z-index: 1
}

.scroller:before {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    content: '';
    display: block;
    z-index: 9999
}

.scroller.mp-pushed {
    cursor: pointer
}

.scroller.mp-pushed:before {
    left: 0;
    bottom: 0;
    width: auto;
    height: auto;
    background: rgba(0,0,0,.5);
    transition: background .5s;
    -webkit-transition: background .5s
}

.mp-pusher.fancy_visible {
    transform: translateX(340px);
    -webkit-transform: translateX(340px);
    -ms-transform: translateX(340px)
}

.mp-pusher.menu_visible {
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%)
}

.mp-pusher::after {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    content: '';
    opacity: 0
}

.mp-pusher.fancy_visible {
    transform: translateX(340px);
    -webkit-transform: translateX(340px);
    -ms-transform: translateX(340px)
}

#mp-fancy > .header {
    border-bottom: 5px solid #6b7e9b;
    position: relative
}

#menu_launcher {
    position: absolute;
    width: 4rem;
    height: 4rem;
    margin: auto;
    color: #fff;
    font-size: 2rem;
    cursor: pointer
}

#menu_launcher a {
    display: block
}

#menu_launcher .text {
    vertical-align: middle;
    font-size: 1.1rem;
    color: #191818;
    line-height: 1;
    padding-bottom: 5px;
    text-transform: uppercase
}

.picto-menu {
    position: absolute;
    top: 16px;
    left: 6px;
    width: 28px;
    height: 2px;
    background: #6b7e9b;
    display: inline-block;
    transition: all .3s ease-in-out 0s
}

.picto-menu:after,
.picto-menu:before {
    background: #6b7e9b none repeat scroll 0 0;
    content: "";
    display: inline-block;
    height: 2px;
    left: 0;
    position: absolute;
    transform-origin: center center 0;
    -webkit-transform-origin: center center 0;
    transition: all .3s ease-in-out 0s;
    width: 28px
}

.picto-menu:before {
    top: 8px
}

.picto-menu:after {
    top: -8px
}

.menuhidden .picto-menu:before {
    height: 2px;
    transform: rotate(45deg)
}

.menuhidden .picto-menu:after {
    height: 2px;
    transform: rotate(-45deg)
}

.menuhidden .picto-menu:after,
.menuhidden .picto-menu:before {
    top: 0;
    transform-origin: 50% 50% 0
}

.menuhidden .picto-menu {
    background: transparent none repeat scroll 0 0
}

.m_icon {
    display: inline-block;
    position: relative;
    margin: 10px 0;
    height: 2px;
    width: 24px;
    background: #191818;
    vertical-align: middle
}

.m_icon:before {
    content: '';
    display: block;
    position: absolute;
    top: -8px;
    left: 0;
    height: 2px;
    width: 24px;
    background: #191818
}

.m_icon:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -8px;
    left: 0;
    height: 2px;
    width: 24px;
    background: #191818
}

#mh_close {
    text-align: right
}

#mh_close .icon {
    padding: 10px;
    cursor: pointer
}

#logo_mh {
    text-align: center;
    width: 14rem;
    margin: 5px auto
}

#logo_mh img {
    height: 6rem;
    object-fit: contain
}

#mp-menu #mh_close {
    cursor: pointer;
    width: 4rem;
    height: 4rem;
    position: absolute;
    top: .8rem;
    right: 2rem
}

.symbol {
    display: inline-block;
    font-size: 1rem;
    position: relative
}

.symbol-close {
    width: 30px;
    height: 2px;
    margin: 19px 5px;
    background: #6b7e9b;
    position: relative;
    transform: rotate(45deg)
}

.symbol-close:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    height: 2px;
    background: #6b7e9b;
    transform-origin: 50% 50%;
    transform: rotate(90deg)
}

.mp-level li.menu_mobile_social {
    display: flex;
    justify-content: center;
    padding-top: .5rem;
    box-shadow: inset 0 1px rgba(0,0,0,.2)
}

.mp-level li.menu_mobile_social > a {
    box-shadow: none;
    margin: 0 1rem
}

.mp-level li.menu_mobile_social > a .icon {
    font-size: 2.5rem
}

.mp-level li.menu_mobile_contact {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: .5rem;
    box-shadow: inset 0 1px rgba(0,0,0,.2);
    padding-left: 20px
}

.mp-level li.menu_mobile_contact > a {
    box-shadow: none;
    margin: 0 1rem
}

@media (min-width:993px) {
    #menu_launcher {
        display: none
    }
}

@media (max-width:575.98px) {
    #menu_launcher {
        top: 1.5rem;
        left: .8rem
    }
}

@media (max-width:767.98px) {
    #menu_launcher {
        top: 1rem;
        left: .8rem
    }
}

@media (max-width:992px) {
    #menu_launcher {
        top: 1rem;
        left: .8rem
    }

    .mp-level.mp-level-open.mp-level-overlay {
        overflow: hidden;
        height: 200vh
    }
}