#header .link_logo {
    position: relative
}

#header .link_logo:after {
    content: attr(data-instance);
    color: #216a2f;
    position: absolute;
    left: 50%;
    bottom: -40px;
    right: 47px;
    transform: translateX(-65%);
    font-size: 22px;
    font-weight: 600
}
/*! Popin SEO */
#desc_f,
#desc_h {
    position: relative
}

#desc_h {
    text-align: justify;
    margin: 0 auto 2rem auto;
    padding: 2rem;
    border-radius: 1rem;
    overflow: hidden;
    background-color: #fff
}

#colorbox #desc_h {
    padding: 4rem 4rem .5rem;
    font-size: 110%;
    text-align: justify
}

#desc_f {
    text-align: center;
    margin-top: 1.5rem;
    overflow: hidden;
    font-size: 1.2rem;
    font-style: italic
}

#desc_f .link_plus {
    margin-left: .5rem
}

#desc_f .link_plus a {
    color: #6e6e6e
}

#desc_f .link_plus a:focus,
#desc_f .link_plus a:hover {
    color: #3d3d3d
}

.cd-main {
    z-index: 21;
    overflow-y: auto;
    backface-visibility: hidden;
    transition: transform .7s;
    transition-timing-function: cubic-bezier(.91,.01,.6,.99)
}

.cd-nav {
    position: fixed;
    z-index: 20;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    visibility: hidden;
    transition: visibility 0s .7s
}

#kpop_contain {
    margin-bottom: 2rem
}

#kpop_contain img.fullimg {
    max-height: 80vh
}

#kpop_contain img.fullimg {
    max-height: calc(100vh - 25vh)
}

.navigation-is-open .cd-main {
    transform: translateX(100%)
}

.navigation-is-open .titre,
.navigation-is-open h1 {
    margin-bottom: 2rem
}

.cd-nav .cd-navigation-wrapper {
    text-align: left;
    height: 100%;
    overflow-y: auto;
    padding: 4rem 5% 4rem 5%;
    box-sizing: border-box;
    backface-visibility: hidden;
    transform: translateX(-50%);
    transition: transform .7s;
    transition-timing-function: cubic-bezier(.86,.01,.77,.78)
}

.cd-nav-trigger {
    display: inline-block;
    margin-bottom: 2rem;
    cursor: pointer;
    z-index: 50;
    height: 5.4rem;
    width: 5.4rem;
    background-color: #0082b8;
    border-radius: 50%;
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    transition: transform .5s,background-color .2s ease-in
}

.cd-nav-trigger:focus,
.cd-nav-trigger:hover {
    background-color: #6e6e6e
}

.cd-nav-trigger .cd-nav-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    transform: translateX(-50%) translateY(-50%);
    width: 2.2rem;
    height: .2rem;
    background-color: #fff
}

.cd-nav-trigger .cd-nav-icon::before,
.cd-nav-trigger .cd-nav-icon:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    background-color: inherit;
    transform: translateZ(0);
    backface-visibility: hidden;
    transition: transform .5s,width .5s,top .3s;
    width: 50%
}

.cd-nav-trigger .cd-nav-icon::before {
    transform-origin: right top;
    transform: rotate(45deg)
}

.cd-nav-trigger .cd-nav-icon::after {
    transform-origin: right bottom;
    transform: rotate(-45deg)
}

.cd-nav-trigger svg {
    position: absolute;
    top: 0;
    left: 0
}

.navigation-is-open .cd-nav {
    visibility: visible;
    transition: visibility 0s 0s
}

.navigation-is-open .cd-nav .cd-navigation-wrapper {
    transform: translateX(0);
    transition: transform .5s;
    transition-timing-function: cubic-bezier(.82,.01,.77,.78)
}

.navigation-is-open .cd-nav-trigger {
    transform: rotate(180deg)
}

.navigation-is-open .cd-nav-trigger .cd-nav-icon::after,
.navigation-is-open .cd-nav-trigger .cd-nav-icon::before {
    width: 50%;
    transition: transform .5s,width .5s
}

.navigation-is-open .cd-nav-trigger .cd-nav-icon::before {
    transform: rotate(45deg)
}

.navigation-is-open .cd-nav-trigger .cd-nav-icon::after {
    transform: rotate(-45deg)
}

.no-touch .navigation-is-open .cd-nav-trigger:hover .cd-nav-icon::after,
.no-touch .navigation-is-open .cd-nav-trigger:hover .cd-nav-icon::before {
    top: 0
}
/*! Slider */
.slider_container > ul {
    padding-left: 0
}

.bx-wrapper,
.bx-wrapper .bx-viewport {
    box-shadow: none;
    border: none!important;
    left: auto;
    background: 0 0
}

.bx-wrapper {
    margin-bottom: 0;
    position: static
}

.bx-wrapper img {
    width: 100%;
    display: block
}

.bx-wrapper li,
.bx-wrapper ul {
    margin: auto;
    padding: 0
}

.bx-wrapper .bx-controls-direction .bx-next,
.bx-wrapper .bx-controls-direction .bx-prev {
    background: 0 0;
    text-indent: inherit;
    z-index: 1;
    color: #fff
}

.bx-wrapper .bx-controls-direction a:focus,
.bx-wrapper .bx-controls-direction a:visited {
    color: #fff
}

.bx-wrapper .bx-controls-direction a:active,
.bx-wrapper .bx-controls-direction a:hover {
    color: #0082b8
}

.bx-wrapper .bx-next:before,
.bx-wrapper .bx-prev:before {
    font-family: kfont!important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    font-size: 2.5rem;
    line-height: 4rem;
    height: 4rem;
    width: 4rem;
    text-align: center;
    display: block;
    margin: 0 auto
}

.bx-wrapper .bx-prev:before {
    content: "\EA0F"
}

.bx-wrapper .bx-next:before {
    content: "\EA10"
}
/*! Slick slider */
.slick-next,
.slick-prev {
    z-index: 1000;
    background: rgba(0,0,0,.5);
    width: 60px;
    height: 60px
}

.slick-prev {
    left: 0
}

.slick-next {
    right: 0
}

.slick-next::before,
.slick-prev::before {
    font-family: kfont!important;
    color: #fff;
    transition: color .3s
}

.slick-prev::before {
    content: '\EA0F'
}

.slick-next::before {
    content: '\EA10'
}

.slick-dots li button::before {
    content: "";
    background-color: #77a5bb;
    top: 1.2rem;
    width: 1.3rem;
    height: 1.3rem;
    border-radius: 50%
}
/*! Formulaires */
form.destinations_form .form_control,
form:not(.destinations_form) fieldset.formgroup {
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 1rem;
    border-radius: .4rem;
    background: #ededed
}

.form_control {
    box-sizing: border-box
}

.form_control * {
    box-sizing: border-box
}

#form_date_debut_f_contact_salarie {
    color: #6b7e9b
}

.form_control label {
    display: inline-block;
    margin-bottom: .5rem;
    cursor: pointer;
    width: 11rem
}

.form_control input[type=email],
.form_control input[type=password],
.form_control input[type=text],
.form_control select,
.form_control textarea {
    padding: .5rem 1rem;
    border: .1rem solid rgba(0,0,0,.2);
    outline: 0;
    margin: 0 auto 10px auto;
    border-radius: .2rem;
    line-height: 2.2rem;
    color: #6b7e9b
}

.form_control input[type=email]:focus,
.form_control input[type=password]:focus,
.form_control input[type=text]:focus,
.form_control select:focus,
.form_control textarea:focus {
    border-color: #f2b52d
}

.form_control textarea {
    width: 100%;
    resize: none
}

.form_control .needed {
    color: #77a5bb
}

.label-title {
    font-size: 1.6rem
}

.log_error {
    text-align: left;
    color: #f24f2d;
    margin-bottom: 4rem
}

.log_error li {
    line-height: 2rem;
    margin: 5px 0
}

.log_error li .picto {
    display: inline-block;
    width: 20px;
    height: 20px;
    line-height: 2rem;
    margin-right: 5px;
    text-align: center;
    border: 1px solid #f24f2d;
    border-radius: 50%
}

.error_border {
    border: 2px solid #f24f2d!important
}

.error {
    display: inline-block;
    color: #fff;
    background-color: #f24f2d;
    margin: 10px 0;
    padding: 8px
}

.success {
    display: inline-block;
    color: #000;
    background-color: #00b229;
    margin: 10px 0;
    padding: 8px
}

@media (max-width:768px) {
    .desktop {
        display: none
    }
}

@media (max-width:575.98px) {
    #header .link_logo::after {
        bottom: -35px
    }
}